<template>
<div>
    <section>
        <AdvanceTable ref="advanceTable" url="regularPickup/list" :height="window.height - 280" :columns="columns" :pageOptions="[50, 100, 200]" :defaultPageSize="50">
        <template #title>
            {{ $t("Regular Pick up") }}
        </template>
        <template #button>
            <b-button variant="info" class="mr-1"  :to="{ name: 'create-regular-pickup' }">
              <feather-icon icon="PlusSquareIcon" class="mr-50" />
              <span class="align-middle">{{ $t("Create Regular Pickup") }}</span>
            </b-button>
        </template>
        <template #cell(monday)="row">
          <b-badge variant="danger" style="margin: 0px 2px; padding: 5px" v-if="row.item.monday == 'P'">PM</b-badge>
          <b-badge variant="secondary" style="margin: 0px 2px; padding: 5px" v-if="row.item.monday == 'A'">AM</b-badge>
          <span v-if="row.item.monday == 'A,P' || row.item.monday == 'P,A'">
            <b-badge variant="secondary" style="margin: 0px 2px; padding: 5px" >AM</b-badge>
            <b-badge variant="danger" style="margin: 0px 2px; padding: 5px" >PM</b-badge>
          </span>
        </template>
        <template #cell(tuesday)="row">
          <b-badge variant="danger" style="margin: 0px 2px; padding: 5px" v-if="row.item.tuesday == 'P'">PM</b-badge>
          <b-badge variant="secondary" style="margin: 0px 2px; padding: 5px" v-if="row.item.tuesday == 'A'">AM</b-badge>
          <span v-if="row.item.tuesday == 'A,P' || row.item.tuesday == 'P,A'">
            <b-badge variant="secondary" style="margin: 0px 2px; padding: 5px" >AM</b-badge>
            <b-badge variant="danger" style="margin: 0px 2px; padding: 5px" >PM</b-badge>
          </span>
        </template>
        <template #cell(wednesday)="row">
          <b-badge variant="danger" style="margin: 0px 2px; padding: 5px" v-if="row.item.wednesday == 'P'">PM</b-badge>
          <b-badge variant="secondary" style="margin: 0px 2px; padding: 5px" v-if="row.item.wednesday == 'A'">AM</b-badge>
          <span v-if="row.item.wednesday == 'A,P' || row.item.wednesday == 'P,A'">
            <b-badge variant="secondary" style="margin: 0px 2px; padding: 5px" >AM</b-badge>
            <b-badge variant="danger" style="margin: 0px 2px; padding: 5px" >PM</b-badge>
          </span>
        </template>
        <template #cell(thursday)="row">
          <b-badge variant="danger" style="margin: 0px 2px; padding: 5px" v-if="row.item.thursday == 'P'">PM</b-badge>
          <b-badge variant="secondary" style="margin: 0px 2px; padding: 5px" v-if="row.item.thursday == 'A'">AM</b-badge>
          <span v-if="row.item.thursday == 'A,P' || row.item.thursday == 'P,A'">
            <b-badge variant="secondary" style="margin: 0px 2px; padding: 5px" >AM</b-badge>
            <b-badge variant="danger" style="margin: 0px 2px; padding: 5px" >PM</b-badge>
          </span>
        </template>
        <template #cell(friday)="row">
          <b-badge variant="danger" style="margin: 0px 2px; padding: 5px" v-if="row.item.friday == 'P'">PM</b-badge>
          <b-badge variant="secondary" style="margin: 0px 2px; padding: 5px" v-if="row.item.friday == 'A'">AM</b-badge>
          <span v-if="row.item.friday == 'A,P' || row.item.friday == 'P,A'">
            <b-badge variant="secondary" style="margin: 0px 2px; padding: 5px" >AM</b-badge>
            <b-badge variant="danger" style="margin: 0px 2px; padding: 5px" >PM</b-badge>
          </span>
        </template>
        <template #cell(status)="row">
          <b-badge variant="info" style="margin: 0px 2px; padding: 5px" v-if="row.item.status == true">ACTIVE</b-badge>
          <b-badge variant="warning" style="margin: 0px 2px; padding: 5px" v-if="row.item.status == false">INACTIVE</b-badge>
        </template>
        <template #cell(active)="row">
          <feather-icon icon="Edit3Icon" @click="openModalForEdit(row.item)" />
        </template>
        </AdvanceTable>
    </section>
</div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AdvanceTable from "@core/components/advanceTable/advanceTable.vue";
import flatPickr from "vue-flatpickr-component";
export default {
    components: {
    ToastificationContent,
    AdvanceTable,flatPickr
  },
  data() {
    return {
        window: {
            width: 0,
            height: 0
        },
        columns: [
        { key: 'companyName', modelName: "contactName",label: this.$t('Company Name'),width: "180", filtertype: "input", sortable: true },
        { key: 'keAccountNumber', modelName: "keAccountNumber",label: this.$t('Fox Acount'), width: "160", filtertype: "input",sortable: true },
        { key: 'sortCode', modelName: "sortCode",label: this.$t('Route'), filtertype: "input",width: "160", sortable: true },
        { key: 'pickupAdd', modelName: "pickupAdd",label: this.$t('Pick up Address'), width: "180", filtertype: "input",sortable: true },
        { key: 'monday', modelName: "monday",label: this.$t('Mon'), width: "100", filtertype: "select",
          options: {
            A:"AM",
            P:"PM",
            "A,P":"AM+PM",
            empty:"EMPTY"
          },sortable: true },
        { key: 'tuesday', modelName: "tuesday",label: this.$t('Tue'), width: "100", filtertype: "select",
          options: {
            A:"AM",
            P:"PM",
            "A,P":"AM+PM",
            empty:"EMPTY"
          },sortable: true },
        { key: 'wednesday', modelName: "wednesday",label: this.$t('Wed'), width: "100", filtertype: "select",
          options: {
            A:"AM",
            P:"PM",
            "A,P":"AM+PM",
            empty:"EMPTY"
          },sortable: true },
        { key: 'thursday', modelName: "thursday",label: this.$t('Thu'), width: "100", filtertype: "select",
          options: {
            A:"AM",
            P:"PM",
            "A,P":"AM+PM",
            empty:"EMPTY"
          },sortable: true },
        { key: 'friday', modelName: "friday",label: this.$t('Fri'), width: "100", filtertype: "select",
          options: {
            A:"AM",
            P:"PM",
            "A,P":"AM+PM",
            empty:"EMPTY"
          },sortable: true },
        { key: 'createdAt', modelName: "createdAt",label: this.$t('Created By'), width: "160", filtertype: "datetime",sortable: true },
        { key: 'createdBy', modelName: "userName",label: this.$t('Create At'), width: "100", filtertype: "input",sortable: true },
        { key: 'status', modelName: "status",label: this.$t('Status'), width: "100", filtertype: "select",options: { false: "INACTIVE", true: "ACTIVE"},sortable: true },
        { key: 'active', modelName: "active",label: this.$t(''), width: "80", },
      ],
      show:false
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods:{
    openModalForEdit(item) {
      console.info(item,'----------')
      this.$router.push({ name: "create-regular-pickup", params:{id:item.id} });
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  }
}
</script>